import {
    Input,
    InputNumber,
    Select,
    Switch,
    Button,
    DatePicker,
    TimePicker,
    Form,
    Upload,
    Radio,
    Space,
    Card,
    message,
    Descriptions,
    Divider,
  } from "antd";
  import {
    InstagramOutlined,
    GlobalOutlined,
    LinkedinOutlined,
    CloseOutlined,
    WhatsAppOutlined,
    YoutubeOutlined,
  } from "@ant-design/icons";
  import { useState } from "react";
  import supabase from "pages/supa-base-client/supabase";
  import { cashfree } from "util/cashfree";
  import {
    getCouponValidity,
    getCourseBatch,
    getNotification,
    getPaymentResponse,
  } from "util/methods";
import constants from "util/constants";
  
  const Hack = () => {
    return (
      <Card title="Techknow 2.0">
        <p>
    Welcome to the world of tech where you ignite the passion for code. 
Techknowthon 2.0, the hackathon hosted by us, will help you ignite your love for coding whilst you network amongst the tech heads of the world! 
</p>
<br/>
<p>So, what are you waiting for? Join this revolution, where you {`don't`} just code the future, but co-create it!</p>
<br/>
<p><b>For Students :</b></p>
<p><b>Techknowthon 2.0 (Hackathon) + Summit</b></p>
<p>
Per Student : Rs 499/-
</p>
<br/>
<p><b>Techknow 2.0 (Only Summit)</b></p>
<p>
Per Student : Rs 399/-
</p>
<br/>
<p><b>For Corporates :</b></p>
<p><b>Techknowthon 2.0 (Hackathon) + Summit</b></p>
<p>
Per Person : Rs 699/-
</p>
<br/>
<p><b>Techknow 2.0 (Only Summit)</b></p>
<p>
Per Student : Rs 599/-
</p>
<br/>
<p>
<b>Perks:</b> 
</p>
<ol>
    <li>1. Winners will be awarded with a cash prize and internship in Knowvation or its collaboration Companies.</li>
    <li>2. Certificate of Appreciation</li>
    {/* <li>3. Free premium of the iCube platform for 2 months</li> */}
    <li>3. Opportunity to Network </li>
    <li>4. Company Goodies </li>
</ol>
    <p>and many more!</p>
<br/>
        <p>Also, Find us on:</p>
        <p>
          <a href={constants.INSTAGRAM_URL}>
            <LinkedinOutlined /> Our Instagram
          </a>
          <br />
          <a href={constants.INSTAGRAM_URL}>
            <InstagramOutlined /> Our LinkedIn
          </a>
          <br />
          <a href={"https://chat.whatsapp.com/GJgvghlly1ZKawu4AhqBcG"}>
            <WhatsAppOutlined /> Our Whatsapp Community
          </a>
          <br/>
          <a href={constants.YOUTUBE_URL}>
            <YoutubeOutlined /> Our Youtube Channel
          </a>
        </p>
      </Card>
    );
  };
  
  const Techknow = ({ eventId }) => {
    const [formType, setFormType] = useState("summit");
    const [team, setTeam] = useState(1);
    const [summary, setSummary] = useState({
      coupon:"",
      couponAmount:0,
      total:0,
      subTotal:499,
      discount:0,
      type:"percentage"
    });
  
    const calculateSummary = (value, count) => {
      if(value === undefined)
        value = formType;
      if(count === undefined)
        count = team;
      if(value === "summit"){
        setSummary({...summary,total:499*team,subTotal:499}) ;
      }
      else{
        setSummary({...summary,total:699*team,subTotal:699}) ;
      }
    }
  
    const getTotal = (value,count) => {
      let subTotal = getSubTotal();
      let amount = count * subTotal;
      let disc = 0;
      if(summary.couponAmount === 0)
        return amount;
      if(summary.type === "percentage"){
        disc = (amount / 100) * summary.couponAmount;
        amount = amount - discount_amount;
      }
      else{
        amount = amount - summary.couponAmount;
      }
      return amount;
    }
  
    const onTypeChange = (value) => {    
      setFormType(value);
    }; 
  
    const onFinish = async (values) => {
      try {
        console.log({values})
        const {data, error: err} = await supabase.from("responses").insert({ form_id: '9e3c161f-7bc0-472e-af71-9c4d22a26821', data: values }).select();
        
        if(err !== null || data === null){
            console.error("Error saving data:", err, data);
            message.error("Encountered error! Please try again later");
            return;
        }

        const registerData = {
            formId : '9e3c161f-7bc0-472e-af71-9c4d22a26821',
            responseId : data[0].id,
            amount: getTotal(formType, team),
            coupon: summary.coupon,
            name: values["primaryName"],
            email: values["primaryEmail"],
            phone: values["phone"]
        }

        fetch("https://api.knowvationlearnings.in/api/v1/pay/payme", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registerData),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                console.log({res})
                let obj = JSON.parse(res.data.Data)
                let url = obj.payment_session_id;
                let checkoutOptions = {
                    paymentSessionId: url,
                    redirectTarget: "_blank",
                };
                if (url === null || url === undefined) {
                    message.error(
                        "Error in initiating the payments. Please try again!"
                      );
                    return;
                }
                cashfree.checkout(checkoutOptions);
              });
            } else {
              message.error(
                "Error in initiating the payments. Please try again!"
              );
            }
          })
          .catch((error) => {
            console.error("Error initiating payment:", error);
            message.error("Encountered error! Please try again later");
          });
      } catch (error) {
        console.error("Error initiating payment:", error);
        message.error("Encountered error! Please try again later");
      }
    };
    const onFinishFailed = (errorInfo) => {
        if (errorInfo.values.members === undefined || errorInfo.values.members === null || errorInfo.values.members.length === 0)
          message.error("Add atleast anotherver Team Member!");
    };
  
    const selectedEvent = () => {
      for(let i=0;i<events.length;i++){
        if(formType === events[i].value)
          return events[i].label;
      }
    };
  
    const events = [
    //   {
    //   "label":"Mega Pass - Hackathon + Summit",
    //   "value":"megapass"
    // },
    {
      "label":"Techknowthon 2.0 (Hackathon) + Summit",
      "value":"techknowthon"
    },
    //{
    //  "label":"Debattle - Tech Debate",
    //  "value":"debattle"
    //},
    {
      "label":"Summit",
      "value":"summit"
    }
  ];
    const sizes = [
      {
      "label":"Small",
      "value":"s"
    },
      {
      "label":"Medium",
      "value":"m"
    },
      {
      "label":"Large",
      "value":"l"
    },
      {
      "label":"Extra Large",
      "value":"xl"
    },
  ];

  const applyCoupon = async () => {
        const values = form.getFieldsValue();
        const { coupon } = values;
        const res = await getCouponValidity(coupon);
        if (!res || !res?.valid) {
            message.error("Coupon invalid");
          return;
        }
        message.success("Coupon Applied Successfully")
        setSummary({coupon:coupon, couponAmount: res.amount, type: res.type,...summary});
      };
  
  const getSubTotal = () => {
    switch(formType){
        case 'summit':{
            if(resType === "student"){
                return 399;
            }
            else{
                return 599;
            }
        }
        case 'techknowthon':{
            if(resType === "student"){
                return 499;
            }
            else{
                return 699;
            }
        }
        default:return 499;
    }
  }

  const getDiscount = (value) => {
    console.log({summary})
    let amount = getSubTotal();
    if(value.couponAmount === 0)
        return 0;
      if(value.type === "percentage"){
        return (amount / 100) * value.couponAmount;
      }
      return value.couponAmount;
      
  }
  
  const Summary = () => <Card title="Summary">
  <Descriptions title="Event Info" layout="horizontal">
    <Descriptions.Item label="Selected Event" span={2}>
      {selectedEvent()}
    </Descriptions.Item>
  </Descriptions>
  
  <Divider orientation="right" dashed>
    Sub Total : {"₹ "+  getSubTotal() } x { team }
  </Divider>
  {/* <Divider orientation="right" dashed>
    Applied Discount : {"₹ "+  getDiscount(summary) }
  </Divider> */}
  <Divider orientation="right">
    Grand Total : {"₹ "+ getTotal(formType, team)}
  </Divider>
  </Card>
  
    const [form] = Form.useForm();
    const [resType, setResType] = useState("student");
  
    return (
      <>
        <div className="section row mb-5 items-center justify-center">
        <Hack />
          <div className="col-12 rounded-lg mt-4 md:col-6">
            <Form
              labelCol={{
                span: 8,
              }}
              form={form}
              wrapperCol={{
                span: 14,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="userType"
                label="You are?"
                rules={[{ required: true }]}
              >
                <Select onChange={(value) => setResType(value)}
                  options={[
                    { label: "Student", value: "student" },
                    { label: "Corporate", value: "corporate" },
                    { label: "College Management", value: "management" },
                    { label: "Others", value: "others" },
                  ]}
                ></Select>
              </Form.Item>
              {resType === "others" && <Form.Item name="resType" label="Please Mention">
                <Input />
              </Form.Item>}
              
              <Form.Item
                name="type"
                label="Registering For"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select Event"
                  onChange={onTypeChange}
                  dropdownStyle={{ width: "15rem" }}
                  options={events}
                >
                </Select>
              </Form.Item>
              <Form.Item
                name="primaryName"
                label="Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="primaryEmail"
                label="Primary Email"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
              <Form.Item
                    name="college"
                    label="College Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                              label="Year of Study"
                              name={"year"}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
              {formType === 'techknowthon' ? (
                <>
                  <Form.Item
                    name="team"
                    label="Team Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>                               
                  
                  <Form.List name="members" rules={[{ required: true }]}>
                    {(fields, { add, remove }) => (
                      <div
                        style={{
                          display: "flex",
                          rowGap: 16,
                          flexDirection: "column",
                        }}
                      >
                        {fields.map((field) => (
                          <Card
                            size="small"
                            title={`Member ${field.name + 1}`}
                            key={field.key}
                            extra={
                              <CloseOutlined
                                onClick={() => {
                                  setTeam(team-1);
                                  remove(field.name);
                                }}
                              />
                            }
                          >
                            <Form.Item
                              label="Name"
                              name={[field.name, "name"]}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Email"
                              name={[field.name, "email"]}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Phone"
                              name={[field.name, "phone"]}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Year of Study"
                              name={[field.name, "year"]}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Card>
                        ))}
                        {fields.length === 3 ? (
                          <></>
                        ) : (
                          <Button
                            style={{ marginBottom: "1rem" }}
                            type="dashed"
                            onClick={() => { setTeam(team+1);add();}}
                            block
                          >
                            + Add Member
                          </Button>
                        )}
                      </div>
                    )}
                  </Form.List>
                </>
              ) : (
                <></>
              )}                                
                {/* <Form.Item wrapperCol={{
                  span: 24,
                }} className="text-lato" name="coupon">
                <div className="flex justify-center flex-row gap-4">
                    <Input
                    placeholder="Enter Coupon Code"
                    />
                    <Button
                    onClick={() => applyCoupon()}
                    style={{
                        boxShadow: "6px 6px 0px 3px #00B902",
                    }}
                    className="text-lato"
                    >
                    Apply
                    </Button>
                </div>
                </Form.Item> */}
              <Form.Item wrapperCol={{
                  span: 26,
                }}>
                  <Summary />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 10,
                  span: 16,
                }}
              >
                <Button
                  style={{
                    boxShadow: "6px 6px 0px 3px #00B902",
                }}
                  htmlType="submit"
                  className="text-lato"
                >
                  Submit
                </Button>
              </Form.Item>
              <Form.Item name="eventId" initialValue={eventId}>
                <Input type="hidden" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  };
  
  export default Techknow;
  